import React from "react";

// others
import { Col, Space, Typography } from "antd";
import { DoubleRightOutlined } from "@ant-design/icons";
import { numberWithCommas } from "../utils";
interface IProps {
  label: string;
  value?: any;
  valueLink?: any;
  valueStyle?: object;
  bookingExpiry?: Boolean;
  showModal?: any;
  requestExtenstionShow?: any;
  renewalAmout?: any;
  amountOfAdjust?: any;
  renewalAmoutAdjust?: any;
  amountTypeCheck?: any;
  tenantLink?: any;
  checkTenantInfo?: any
  settlementSummary?: any

}

const toNormalCase = (str) => str?.charAt(0)?.toUpperCase() + str?.slice(1);


const index = (props: IProps) => {

  const showModalContent = () => {
    props?.requestExtenstionShow === 'booking_extension_requested' ? props.showModal(false) : props.showModal(true)
  }


  const { Text } = Typography;


  const strikeThroughForRenealTerms = (newValue, oldValue) => {

    if (props?.amountOfAdjust && props?.renewalAmoutAdjust) {

      if (props?.amountOfAdjust === props?.renewalAmoutAdjust) {

        return false
      } else {
        return 'AED' + " " + numberWithCommas(props?.renewalAmoutAdjust);
      }
    }
    else if (newValue === oldValue) {
      return false
    } else {
      return oldValue;
    }

  }

  return (
    <>
      <Col span={props?.bookingExpiry ? 6 : 6} style={{ marginBottom: "16px" }}>
        <p className="label" style={{ textTransform: "capitalize" }}>
          {props.label}
        </p>
        {props.value && (
          <p className="value" style={props.valueStyle}>
            <>

              {
                props?.settlementSummary
                
                ?
                 <Text delete>
                  { strikeThroughForRenealTerms(props?.settlementSummary, props?.renewalAmout) &&  `AED ${strikeThroughForRenealTerms(props?.settlementSummary, props?.renewalAmout)}`}
                </Text>
                :
                <Text delete>
                  {(props?.renewalAmout || props?.renewalAmout !== 'AED') && strikeThroughForRenealTerms(props.value, props?.renewalAmout)}
                </Text>

              }

              {"  "}
              {(props.value === 'male' || props.value === 'female') ? props.value.charAt(0).toUpperCase() + props.value.slice(1) : props.value} {

                  props?.tenantLink && (
                  <a
                    className="value"
                     href="#"
                    style={props.valueStyle}
                    onClick={() => {
                      props?.checkTenantInfo()
                    }}
                  >
                     <DoubleRightOutlined />
                  </a>
                )
              }
            </>
          </p>
        )}

        {props.valueLink && (
          <a
            className="value"
            style={props.valueStyle}
            onClick={() => {
              const newTab = window.open();
              newTab.document.write(props?.valueLink?.htmlTemplate);
            }}
          >
            {toNormalCase(props?.valueLink?.templateName)} <DoubleRightOutlined />
          </a>
        )}


      </Col>

      {
        props?.bookingExpiry &&
        <Col span={6} style={{ marginTop: '10px' }}>
          {
            (props?.requestExtenstionShow === 'ok' || props?.requestExtenstionShow === 'booking_extension_requested') &&
            <Space
              aria-disabled
              className={props?.requestExtenstionShow === 'booking_extension_requested' ? "history-container-booking-extension-disabled" : "history-container-booking-extension"}
              onClick={() => showModalContent()}
              style={{
                letterSpacing: "1px",
              }}
            >

              <p className="history-label">{props?.requestExtenstionShow != 'booking_extension_requested' ? 'Request extension' : 'Extension requested'}</p>
            </Space>
          }
        </Col>
      }
    </>
  );
};

export default index;
