import React, { useMemo } from "react";

// others

import { Col, Row, Spin } from "antd";
import { calculatePaidAmountAndDueDate } from "../../BookingForm/components/utils";
import { Item } from "../../BookingForm/components/view";
import { numberWithCommas } from "../../BookingForm/components/utils";
interface IProps {
    booking: any;
    kycDetails?: Array<object>;
}

const TerminationDetail = (props: IProps) => {
    // variables

    const isBooking = props?.booking && Object.keys(props.booking).length > 0;
    const _ = require('lodash');
    const { totalPaidAmount, lastPaidDate, securityDepositAmount, securityDepositDate } = useMemo(() => calculatePaidAmountAndDueDate(props.booking?.paymentSchedule), [props.booking?.paymentSchedule]);

    return (
        <Col span={24} className="view-booking">
            {!isBooking && (
                <Row gutter={24} className="loading">
                    <Spin size="large" />
                </Row>
            )}

            {isBooking && (
                <>
                    <Row gutter={24} className="bd-item">
                        <Item
                            label="Penalty Type"
                            value={_.capitalize(props?.booking?.moveOutType)}

                        />
                        <Item
                            label="Move-out Date"
                            value={props?.booking?.vacatingDate ? props?.booking?.vacatingDate?.slice(0, 10) : '-'}
                        />
                        <Item
                            label="Move-out Inspection Date"
                            value={props?.booking?.moveOutInspection ? props?.booking?.moveOutInspection["date"]?.slice(0, 10) : '-'}

                        />
                        <Item
                            label="Lease Term"
                            value={`${props?.booking?.leaseTermDays ? props?.booking?.leaseTermDays : 0} days |
                                    AED ${props?.booking?.leaseTerms?.contractAmount ? (props?.booking?.leaseTerms?.contractAmount) : 0}`}
                        />

                        <Item
                            label="Days Occupied"
                            value={`${props?.booking?.daysOccupied ? props?.booking?.daysOccupied : 0} Days`}
                        />

                        <Item
                            label="Rent Per Day"
                            value={`AED ${props?.booking?.rentPerDay ? (numberWithCommas(props?.booking?.rentPerDay)) : 0}`}
                        />

                        <Item
                            label="Security Deposit"
                            value={`${securityDepositDate} | AED ${(securityDepositAmount)}`}
                        />
                        <Item
                            label="Rent Received Until "
                            value={`${lastPaidDate}  | AED ${numberWithCommas(totalPaidAmount)}`}
                        />
                        <Item
                            label="Actual Rent Due"
                            value={`${props?.booking?.daysOccupied ? props?.booking?.daysOccupied : 0} days | AED ${props?.booking?.actualRentDue ? numberWithCommas(props?.booking?.actualRentDue) : 0}`}
                        />
                        <Item
                            renewalAmout={(props?.booking?.settlementSummary && props?.booking?.settlementApproval	=== "pending") && props?.booking?.settlementSummary?.maintenancefee }
                            label="Maintenance Fee"
                            settlementSummary={(props?.booking?.settlementSummary && props?.booking?.settlementApproval	=== "pending") && props?.booking?.settlementSummary?.maintenancefee }
                            value={`AED ${props?.booking?.settlementSummary?.maintenancefee ? numberWithCommas(props?.booking?.settlementSummary?.maintenancefee) : 0}`}
                        />
                        <Item
                            renewalAmout={(props?.booking?.settlementSummary && props?.booking?.settlementApproval	=== "pending") && props?.booking?.settlementSummary?.refurbismentfee }
                            label="Refurbishment Fee"
                            value={`AED ${props?.booking?.settlementSummary?.refurbismentfee ? numberWithCommas(props?.booking?.settlementSummary?.refurbismentfee) : 0}`}
                            settlementSummary={(props?.booking?.settlementSummary && props?.booking?.settlementApproval	=== "pending") && props?.booking?.settlementSummary?.refurbismentfee } 
                        />

                        <Item
                            renewalAmout={(props?.booking?.settlementSummary && props?.booking?.settlementApproval	=== "pending") && props?.booking?.settlementSummary?.otheradminfee}
                            label="Other / Admin Fee"
                            value={`AED ${props?.booking?.settlementSummary?.otheradminfee ? numberWithCommas(props?.booking?.settlementSummary?.otheradminfee) : 0} `}
                            settlementSummary={(props?.booking?.settlementSummary && props?.booking?.settlementApproval	=== "pending") && props?.booking?.settlementSummary?.otheradminfee }
                        />

                        <Item

                            label="Overstay"
                            value={`${props?.booking?.overstay?.numberOfDays ? props?.booking?.overstay?.numberOfDays : 0} days | AED ${props?.booking?.overstay?.totalOverStayPaneltyAmount ? Number(props?.booking?.overstay?.totalOverStayPaneltyAmount) : 0}`}
                        />

                        <Item
                            label="Termination Charge"
                            value={`AED ${props?.booking?.termination?.totalterminationPaneltyAmount ? numberWithCommas(props?.booking?.termination?.totalterminationPaneltyAmount) : 0}`}
                        />

                        <Item 
                            renewalAmout={(props?.booking?.settlementSummary && props?.booking?.settlementApproval	=== "pending") && props?.booking?.settlementSummary?.deduction}
                            label="Deduction / Waiver"
                            value={`AED ${props?.booking?.settlementSummary?.deduction ? numberWithCommas(props?.booking?.settlementSummary?.deduction) : 0} `}
                            settlementSummary={(props?.booking?.settlementSummary && props?.booking?.settlementApproval	=== "pending") && props?.booking?.settlementSummary?.deduction }
                        />

                        <Item
                            label="Settlement Amount"
                            value={`AED ${props?.booking?.settlementSummary?.settlementAmount ? numberWithCommas(props?.booking?.settlementSummary?.settlementAmount): 0} `}
                        />



                    </Row>

                </>
            )}
        </Col>
    );
};

export default TerminationDetail;
