import React, { useState, useEffect } from "react";
import DashboardLayout from "../../molecules/DashboardLayout";
import {
  Row,
  Col,
  Pagination,
  Card,
  Select,
  message,
  Typography,
} from "antd";

import SectionHeader from "../../molecules/SectionHeader";
import Grid from "../../atoms/Grid";
import { useDispatch, useSelector } from "react-redux";
import { getContactList, setMoveoutFilters } from "../Contracts/ducks/actions";
import dayjs from "dayjs";
import {formatDate, determineTerminationType } from "../../../utils/dateHelper";
import { useSearchHistory } from "../../../utils/filterHelper";
import { setFilterRenewal } from "../App/SearchFilters/ducks/actions";
import { findLeaseStatus } from "../BookingForm/components/utils";
import LoaderTsx from "../Loader.tsx";
import { useNavigate } from "react-router-dom";

const gridProps = {
  sortable: true,
  filter: true,
  resizable: true,
  minWidth: 220,
};

const moveOutDropdown = [
  { id: 1, name: "Edit Move-out", status: "" },
];

const searchKeys = [
  { value: "name", label: "Name" },
  { value: "unitCode", label: "Unit Number", default: true },
  { value: "property", label: "Property" },
  { value: "propertyAdmin", label: "Property Admin" },
];

const MoveOut = (props) => {
  const dispatch:any = useDispatch();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(50);
  const [searchKey, setSearchKeys] = useState(searchKeys);
  const[isLoading,setIsLoading] = useState(false);
  const[exportData,setExportData] = useState(false);
  const moveoutFilters:any = useSelector((state:any) => state?.contracts?.moveoutFilters);

  const { searchHistory } = useSearchHistory(setFilterRenewal);
  const [searchBy, setSearchBy] = useState(moveoutFilters?.searchBy || "unitCode");
  const [search, setSearch] = useState(moveoutFilters?.search ?? "");
  const contractApi = useSelector((state:any) => state?.contracts?.contractList);

  const forMoveOutFlow = [
    { label: "Move-out Confirmed", value: "moveout_confirmed" },
    // { label: "Sent to tenant", value: "sent" },
  ]

  const optionsForStatus = [...forMoveOutFlow]

  const [filterOptionsForMoveOut, setFilterOptionsForMoveOut] = useState([
    {
      name: "status",
      label: "Status",
      initialValue: moveoutFilters?.filter || "moveout_confirmed",
      title: "1",
      options: optionsForStatus,
    },
  ]);



  const [filter, setFilter] = useState(filterOptionsForMoveOut[0].initialValue);
  const [filterStatus, setStatusFilter] = useState(filterOptionsForMoveOut[0].initialValue);

  const MoreAction = (row) => {
    const { Text } = Typography;
    const onChangeHanldler = async (e) => {
      navigate(`/move-out/summery/${row?.data?.parentleaseId}`);
    };


    return (
      <Select
        onChange={onChangeHanldler}
        className="Sentence"
        placeholder="Select an action"
        style={{ width: 150 }}
        allowClear
      >
        <>
          {row.data.renewalStatus === "moveout_confirmed" &&
            moveOutDropdown.map((value, key) => (
              <React.Fragment key={key}>
                <Select.Option value={value.name}>
                  <Text style={{ color: `${value.status}` }}>{value.name}</Text>
                </Select.Option>
              </React.Fragment>
            ))}
        </>
      </Select>
    );
  };

  const setRenewalStatus = (data)=>{
    //  that statement for creating renewal from renwal

    if(data.leaseStatus === "active" && data.renewalStatus === "active"){
     return  findLeaseStatus['to_review']
    }else if(data?.renewalStatus || (data.leaseStatus && data.leaseStatus === "active"? "to_review": data.leaseStatus !== "active" && data?.leaseStatus)){
     return findLeaseStatus[data?.renewalStatus || (data.leaseStatus && data.leaseStatus === "active"? "to_review": data.leaseStatus !== "active" && data?.leaseStatus)]
    }

  }

  const contractsCol = (MoreAction) => [
    {
      width: 250,
      headerName: "Move-out Status",
      field: "renewalStatus || leaseStatus",
      cellRendererFramework: ({ data }) => setRenewalStatus(data),
    },
    {
      headerName: "Property",
      field: "propertyDetails.propertyName",
    },
    {
      headerName: "Unit Number",
      field: "propertyDetails.unitCode",
    },
    {
      headerName: "Tenant",
      field: "tenantDetails.tenantNameEN",
      cellStyle: { textTransform: "CAPITALIZE" },
    },
    {
      headerName: "Move-out Date",
      field: "Move-out Date",
      cellRendererFramework: ({ data }) => {
        return formatDate(data.vacatingDate) || '';
      },
    },
    {
      headerName: "Termination Type",
      field: "Termination Type",
      cellRendererFramework: ({ data }) => {
        return determineTerminationType(data.vacatingDate, data.leaseTerms.leaseEndDate);
      },
    },
    {
      headerName: "Start Date",
      field: "leaseTerms.leaseStartDate",
      minWidth: 150,
      cellRendererFramework: ({ data }) => {
        return formatDate(data.leaseTerms.leaseStartDate);
      },
    },
    {
      sort: "asc",
      headerName: "End Date",
      field: "leaseTerms.leaseEndDate",
      minWidth: 150,
      cellRendererFramework: ({ data }) => {
        return formatDate(data.leaseTerms.leaseEndDate);
      },
    },
    {
      headerName: "Actions",
      field: "m_action",
      filter: false,
      maxWidth: 200,
      cellRendererFramework: MoreAction,
      pinned: 'right',
    },
  ];

  const handlePagination = (page, pageSize) => {
    setCurrentPage(page);
    setLimit(pageSize);
    const params = { renewalDate: filter, renewalStatus: filterStatus };
    setIsLoading(true)
    dispatch(getContactList(pageSize, page, search, searchBy, params,null,setIsLoading));
  };

  useEffect(() => {
    if (moveoutFilters?.searchBy) {
      const setArray = searchKey.map((item) => {
        let val = null;
        if (item.value === moveoutFilters?.searchBy) {
          val = { ...item, default: true };
        } else {
          val = { ...item, default: false };
        }
        return val;
      });
      setSearchKeys(setArray);
    }
  }, [moveoutFilters, moveoutFilters?.searchBy]);

  useEffect(() => {
    if (
      moveoutFilters?.search != search ||
      moveoutFilters?.searchBy != searchBy ||
      moveoutFilters?.filter != filter ||
      moveoutFilters?.filterStatus != filterStatus
    )

    dispatch(setMoveoutFilters({ search, searchBy, filter, filterStatus, currentPage }));
    const params = { renewalDate: filter, renewalStatus: filterStatus };
    if(exportData){
      params['exportData'] = exportData;
      params['totalCount'] = contractApi?.totalCount;
    }
    setCurrentPage(1);
    setIsLoading(true)
    dispatch(getContactList(limit, 1, search, searchBy, params,setExportData,setIsLoading));
  
  }, [search, searchBy, filter, filterStatus,exportData]);

  useEffect(() => {
    let key = Object.keys(searchHistory)[0];
    if (searchKey.length > 0)
      searchKey.map((item) => {
        if (key == item.value) item["default"] = true;
        else item["default"] = false;
        return item;
      });
  }, [searchHistory]);

  function onTopSearch(e) {
    if (e?.length === 0) setSearch("");
    else if (e?.length < 2)
      message.error("Please enter atleast two characters");
    else setSearch(e);
  }

  function onTopSearchBy(e) {
    setSearchBy(e?.value);
  }

  const filterData = (data = []) => {
    const dataCopy = [...data];

    if (dataCopy.length > 0) {
      for (let key in dataCopy) {
        if (
          dataCopy[key].leaseType === "new" &&
          dataCopy[key].leaseStatus === "active"
        ) {
          const leaseTerms = {
            ...dataCopy[key].leaseTerms,
            ...{
              leaseStartDate: dayjs(dataCopy[key].leaseTerms.leaseEndDate)
                .add(1, "day")
                .toISOString(),
              leaseEndDate: dayjs(dataCopy[key].leaseTerms.leaseEndDate)
                .add(1, "year")
                .toISOString(),
            },
          };

          dataCopy[key]["leaseTerms"] = {
            ...dataCopy[key]["leaseTerms"],
            ...leaseTerms,
          };
        }
      }
    }

    return dataCopy;
  };

  const handleFilterChange = (days) => {
      setFilter(days.value);
      setStatusFilter(days.value);
  }

  //  for export data and send in email 

  const exportAction = ()=>{
    setExportData(true)
  }
  return (
    <DashboardLayout load={loading}>
      <Row gutter={[32, 32]}>
        <Col span={24}>
          <Row gutter={[24, 24]}>
            <Col span={24}>
              <SectionHeader
                noBorder={true}
                heading="Move-outs"
                total={contractApi?.totalCount ?? 0}
                search={(e) => onTopSearch(e)}
                searchBy={(e) => onTopSearchBy(e)}
                searchOptions={searchKey}
                defaultSearch={search}
                viewOption={filterOptionsForMoveOut}
                viewOptionLabel={""}
                justifiedItems={true}
                justifyEnd={true}
                downloadAction={exportAction}
                handleView={handleFilterChange}
              />
            </Col>
            <Col span={24}>
              <Pagination
                className="ag-property-text-pagination"
                total={contractApi?.totalCount ?? 0}
                showTotal={(total, range) =>
                  `Showing ${range[0]}-${range[1]} of ${total} Renewals`
                }
                pageSize={contractApi?.perPage ?? 50}
                defaultCurrent={1}
                current={currentPage || moveoutFilters?.currentPage}
                onChange={handlePagination}
              />
            </Col>
            <Col span={24}>
              <Row gutter={[24, 24]} justify="end">
                <Col span={24}>
                  <Card
                    bordered={false}
                    className="ag-nospace-body"
                 
                  >{
                    isLoading ?
                    <LoaderTsx/>
                    :
                    <Grid
                      columns={contractsCol(MoreAction)}
                      pagination={false}
                      defaultSettings={gridProps}
                      noRowlabel="Contract"
                      fullPage={true}
                      data={filterData(contractApi?.data) ?? []}
                    />}
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </DashboardLayout>
  );
};

export default MoveOut;
