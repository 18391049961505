
import React, { useState, useEffect, useRef, Fragment } from "react";
import {
    Layout,
    Row,
    Col,
    Breadcrumb,
    Steps,
    Button,
    message,
    Spin,
    Card,
    Form,
} from "antd";
import { DownOutlined, LoadingOutlined } from "@ant-design/icons";
import { BsXCircleFill, BsDownload, BsCloudUpload, BsFillCheckCircleFill } from "react-icons/bs";

import axios from 'axios';

import { Controller, useForm } from "react-hook-form";
// import {
//   propertyService, serverUrl
// } from "../../../../configs/constants";
import type { UploadFile } from 'antd/es/upload/interface';
import { InboxOutlined } from '@ant-design/icons';
import { contractService } from "../../../../../configs/constants";

import { CSVLink } from "react-csv";

import { Upload } from 'antd';
import { RcFile } from "antd/lib/upload/interface";
const { Dragger } = Upload;
const { Content } = Layout;

var _ = require("lodash");


// CreateProperty;

// uploadBulkOption={uploadBulkOption}
// setUploadBulkOptions={setUploadBulkOptions}
// setDownloadBulkUpdate={setDownloadBulkUpdate}
// setUploadBulkUpdate={setUploadBulkUpdate}
// downloadBulkUpdate={downloadBulkUpdate}
// uploadBulkUpdate={uploadBulkUpdate}

export default (props) => {
    // const [uploadProgress, setUploadprogress] = useState(0);
    const initials = { unit: 0, building: 0 };
    const [failedData, setFailed] = useState([]);
    const [successData, setSuccess] = useState(initials);
    const [loading, setLoading] = useState(false);
    const [renewalFile, setRenewalFile] = useState<UploadFile[]>([]);
    const { control, formState: { errors }, setValue, handleSubmit } = useForm();



    const headers = [
        { label: "Renewal ID", key: "leaseid" },
        { label: "Renewal Status", key: "renewalstatus" },
        { label: "Property Name", key: "propertyname" },
        { label: "Unit Number", key: "unitnumber" },
        { label: "Tenant Name", key: "tenantname" },
        { label: "Property Admin", key: "propertyadmin" },
        { label: "Current Rent", key: "currentrent" },
        { label: "No of Installments", key: "noofinstallments" },
        { label: "Start Date", key: "startdate" },
        { label: "End Date", key: "enddate" },
        { label: "Renewal Rent", key: "renewalrent" },
        { label: "Amount Type", key: "amounttype" },
        { label: "Amount Change", key: "amountchange" },
        { label: "Change Value", key: "changevalue" },
        { label: "Renew No of Installments", key: "renewnoofinstallments" },
        { label: "Send for Approval", key: "sendforapproval" },
        { label: "Reason", key: "reason" },
    ];


    const csvReport = {
        data: failedData,
        headers: headers,
        filename: 'Failure_log.csv'
    };




    const resetModal = () => {

        setRenewalFile([]);
        setFailed([]);
        setSuccess(initials);
    }

    const onFinish = async (values) => {


        resetModal();
        setLoading(true);



        if (renewalFile[0]) {

            let fd = new FormData();
            fd.append('file_asset', renewalFile[0] as RcFile);
            fd.append('type', props?.filterStatus);
            const config = { headers: { 'Content-Type': 'multipart/form-data', 'Accept': 'text/csv', } };

            const url = `${contractService}/booking/uploadBulkRenewal`;

            await axios.post(url, fd, config).then((response) => {

                const { data } = response;
               
                    if (data?.result?.failedData?.length > 0) {
                        const failedArry = _.map(data.result.failedData, (e) => {
                            return {
                                leaseid: e.leaseid,
                                renewalstatus: e.renewalstatus,
                                propertyname: e.propertyname,
                                unitnumber: e.unitnumber,
                                tenantname: e.tenantname,
                                propertyadmin: e.propertyadmin,
                                currentrent: e.currentrent,
                                noofinstallments: e.noofinstallments,
                                startdate: e.startdate,
                                enddate: e.enddate,
                                renewalrent: e.renewalrent,
                                amounttype: e.amounttype,
                                amountchange: e.amountchange,
                                changevalue: e.changevalue,
                                renewnoofinstallments: e.renewnoofinstallments,
                                sendforapproval: e.sendforapproval,
                                reason: e.reason,
                            }
                        });
                        setFailed(failedArry);
                        setRenewalFile([]);
                    }
                if (data?.result?.processedData?.length > 0) {
                    message.success(response.data.message);
                    if(data?.result?.failedData?.length === 0){
                        props?.setVisible(false);
                    }
                   }
                setLoading(false);
                setRenewalFile([]);

            }).catch((error) => {

                setLoading(false);
                message.error(error.response.data.error)

            })

        } else {
            setLoading(false);
            message.error("Please select a file to upload");
        }

    };


    const propertyFileProps = {
        className: "file-upload",
        name: "file",
        accept: '.csv',
        multiple: false,
        // showUploadList: false,
        onChange(info) {
            const { status } = info.file;
            if (status !== 'uploading') {

            }
            if (status === 'done') {
                message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                message.error(`${info.file.name} Please upload only CSV file`);
            }
        },
        onDrop(e) {

            // setRenewalFile(e.dataTransfer.files);
        },
        onRemove: (file) => {
            setRenewalFile([]);
        },
        beforeUpload: (file) => {

            setRenewalFile([file]);
            return false;
        },
        renewalFile,
    };


    const BulkUploadOptions = () => {

        return (
            <div style={props?.uploadBulkOption == 0 ? { display: 'contents' } : { display: 'none' }}>
                <Col span={6}></Col>
                <Col span={6}>
                    <Button onClick={() => props.setUploadBulkOptions(1)} htmlType="button" disabled={loading} type="primary" size="large" className="csv_publish">
                        {<BsCloudUpload />} Upload
                    </Button>
                </Col>
                <Col span={6}>
                    <Button onClick={() => props?.setDownloadBulkUpdate(true)} htmlType="button" disabled={loading} type="primary" size="large" className="csv_publish">
                        {<BsDownload />} Download Renewals
                    </Button>
                </Col>
                <Col span={4}></Col>
            </div>


        )

    }
    return (

        <Layout className="layout-space prop_popup">
            <Content className="main-content">

                <Row gutter={24}>

                    <BulkUploadOptions />

                    <Col flex="1 1 500px" style={props?.uploadBulkOption == 0 ? { display: 'none' } : { display: 'contents' }}>
                        {/* File Uploader */}
                        <div style={{ textAlign: "center" }}>
                            {/* <h1 className="csvheading">Upload your data through CSV file</h1> */}

                            <Form layout="vertical" onFinish={handleSubmit(onFinish)}>
                                <Row gutter={24}>


                                    <Dragger {...propertyFileProps}
                                        fileList={renewalFile}

                                        className="drag_drop_csv">
                                        <p className="ant-upload-drag-icon">
                                            <InboxOutlined />
                                        </p>
                                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                        <p className="ant-upload-hint">
                                            Download the sample template and fill the data as per the template, convert master file into CSV and upload it.
                                            <br></br><br></br>
                                            <span>Note: </span>Only CSV files are accepted.
                                        </p>
                                    </Dragger>
                             
                                    <Col span={24} className="csv_error_wrap">
                                        {loading ? (
                                            <Spin />
                                        ) :

                                            (<>
                                                   
                                                {failedData?.length > 0 ? (
                                                    <div className="csv_error_log" style={(renewalFile?.length > 0 ) ? {marginTop:'30px'} : {marginTop:'0px'}}>
                                                        <span className="csv_error"> {<BsXCircleFill color='red' />} {failedData?.length} - Records Failed <CSVLink className="csv_log" {...csvReport}>{<BsDownload />} Download Failure Log</CSVLink></span><br />
                    
                                                    </div>
                                                ) : ""}

                                            </>)}
                                        <Button style={(renewalFile?.length > 0 || failedData?.length > 0 ) ? {marginTop:'25px'} : {marginTop:'0px'}} htmlType="submit" disabled={loading} type="primary" size="large" className="csv_publish">
                                            {<BsCloudUpload />} Upload
                                        </Button>
                                    </Col>


                                </Row>
                            </Form>

                            <br />
                        </div>
                    </Col>

                </Row>
            </Content>
        </Layout>
    );
};
